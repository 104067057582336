import Vue from "vue";
import App from "./App.vue";

// Mobile device checking
import { ADD_BODY_CLASSNAME } from "@/core/services/store/htmlclass.module.js";
import { SET_DEVICE } from "./core/services/store/store.module";
import KTUtil from "@/assets/js/components/util";
// import { main } from "@popperjs/core";

const isMobileDevice = KTUtil.isMobileDevice();
if (isMobileDevice) {
  store.dispatch(ADD_BODY_CLASSNAME, "isMobile");
  store.dispatch(ADD_BODY_CLASSNAME, "bg-white");
  store.dispatch(SET_DEVICE, "mobile");

  // Reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);
}
if (!isMobileDevice) {
  store.dispatch(ADD_BODY_CLASSNAME, "isDesktop");
  store.dispatch(SET_DEVICE, "desktop");
}

// Stores, auth and api
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
//import MockService from "@/core/mock/mock.service"; // Desactiver pour LIVE

import { INIT, VERIFY_AUTH } from "@/core/services/store/auth.module";
import storage from "@/core/services/storage.service";

import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import { RESET_ALL_DRAFT, RESET_FILTERED_OPERATIONS } from "@/core/services/store/neoopatrace/datas.module";

// Import Swal
//https://github.com/avil13/vue-sweetalert2/issues/38
//https://github.com/avil13/vue-sweetalert2
//https://codesandbox.io/s/v0xw6v0575?file=/src/App.vue:914-1054
import Swal from "sweetalert2";

//import VueSweetalert2 from "vue-sweetalert2";
// If you don't need the styles, do not connect
// import "sweetalert2/dist/sweetalert2.min.css";
// Options
// const options = {
//   //confirmButtonClass: "btn btn-secondary",
//   confirmButtonClass: "btn btn-outline-secondary",
//   //heightAuto: false,
//   timer: 10000,
// };
// Use vue Swal
//Vue.use(VueSweetalert2, options);

window.Swal = Swal;
window.swalAlert = Swal.mixin({
  showConfirmButton: true,
  //confirmButtonClass: "btn btn-secondary",
  buttonsStyling: false,
  // confirmButtonClass: "btn btn-outline-secondary",
  // cancelButtonClass: "btn btn-outline-secondary",
  //heightAuto: false,
  timer: undefined,
  customClass: {
    content: "mt-3",
    actions: "mt-2",
    confirmButton: "btn btn-outline-secondary",
    cancelButton: "btn btn-outline-secondary",
  },
});
window.swalAction = Swal.mixin({
  showConfirmButton: true,
  buttonsStyling: false,
  // confirmButtonClass: "btn btn-success",
  // cancelButtonClass: "btn btn-outline-secondary",
  //heightAuto: false,
  timer: undefined,
  customClass: {
    content: "mt-3",
    actions: "mt-2",
    confirmButton: "btn btn-success",
    cancelButton: "btn btn-outline-secondary",
  },
});
window.swalError = Swal.mixin({
  showConfirmButton: true,
  buttonsStyling: false,
  // confirmButtonClass: "btn btn-outline-light text-white text-hover-danger bg-transparent bg-hover-white",
  // cancelButtonClass: "btn btn-outline-light text-white text-hover-danger bg-transparent bg-hover-white",
  icon: "error",
  iconColor: "#FFF",
  color: "#FFF",
  timer: 30000000,
  heightAuto: false,
  showClass: {
    backdrop: "swal2-noanimation", // disable backdrop animation
    popup: "", // disable popup animation
    icon: "", // disable icon animation
  },
  hideClass: {
    popup: "", // disable popup fade-out animation
  },
  customClass: {
    popup: "bg-danger",
    icon: "border-light",
    title: "text-light",
    content: "text-light mt-3",
    actions: "mt-2",
    // confirmButton: "btn btn-outline-light",
    // denyButton: "btn btn-outline-light",
    // cancelButton: "btn btn-outline-light",
    footer: "border-dark-danger",
    confirmButton: "btn btn-outline-light text-white text-hover-danger bg-transparent bg-hover-white",
    cancelButton: "btn btn-outline-light text-white text-hover-danger bg-transparent bg-hover-white",
  },
});
window.swalWarning = Swal.mixin({
  showConfirmButton: true,
  //confirmButtonClass: "btn btn-secondary",
  buttonsStyling: false,
  // confirmButtonClass: "btn btn-outline-warning text-warning text-hover-warning bg-transparent bg-hover-white",
  // cancelButtonClass: "btn btn-outline-warning text-warning text-hover-warning bg-transparent bg-hover-white",
  icon: "warning",
  //heightAuto: false,
  timer: undefined,
  customClass: {
    content: "mt-3",
    actions: "mt-2",
    popup: "bg-light-warning",
    title: "text-warning",
    footer: "text-warning",
    confirmButton: "btn btn-outline-warning text-warning text-hover-warning bg-transparent bg-hover-white",
    cancelButton: "btn btn-outline-warning text-warning text-hover-warning bg-transparent bg-hover-white",
  },
});
window.swalToast = Swal.mixin({
  buttonsStyling: false,
  // confirmButtonClass: "btn btn-outline-secondary",
  // cancelButtonClass: "btn btn-outline-secondary",
  showConfirmButton: false,
  toast: true,
  timer: 3000,
  position: "top-end",
  timerProgressBar: true,
  didOpen: (s) => {
    s.addEventListener("mouseenter", window.Swal.stopTimer); // Not working in main.js
    s.addEventListener("mouseleave", window.Swal.resumeTimer); // Not working in main.js
  },
  // Ok
  showClass: {
    popup: "animate__animated animate__faster animate__fadeInDown",
  },
  hideClass: {
    popup: "animate__animated animate__faster animate__fadeOutUp",
  },
  // confirmButtonClass: "btn btn-secondary",
  customClass: {
    popup: "px-5 align-items-end",
    header: "",
    content: "mt-0 ml-5",
    confirmButton: "btn btn-outline-secondary",
    cancelButton: "btn btn-outline-secondary",
  },
});
// Use in vue :       window.swalAlert.fire("TEST ##");
// With options :     window.swalToast.fire({...});

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
//import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
// import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";

// NeoOpatrace 3rd party plugins
import GetTextPlugin from "vue-gettext";
import translations from "@/core/config/gettext/translations.json";
//import * as VueGoogleMaps from "vue2-google-maps";
//import { VueGoogleMaps } from "vue2-google-maps";
import VueGoogleMaps from "gmap-vue"; //https://github.com/diegoazh/gmap-vue

import _ from "lodash";
const VueInputMask = require("vue-inputmask").default; // Import VueInputMask

// NeoOpatrace Mixins
import titleMixin from "@/core/mixins/title-mixin.js"; //ADD VICTOR

// NeoOpatrace Funcs
import { mainFunctions } from "@/core/functions/main-functions.js"; // ADD WIL

// NeoOpatrace Filters
import filters from "@/core/functions/main-filters.js"; // ADD WIL

import VueExcelXlsx from "vue-excel-xlsx";

// Axios helper
// API service init
ApiService.init();

// Remove this to disable mock API
// @JUSTIN Ligne a commenter pour passer en appels API reels
// MockService.init(); // Desactiver pour LIVE
Vue.config.useMockService = typeof MockService === "object";

// Load GetTextPlugin
// Add a context function                   {{ $pgettext("my_context", "My message") }}
// Add a translators comment component      <translate translate-comment=""></translate>
// Add a translators comment directive      v-translate translate-comment="">
Vue.use(GetTextPlugin, {
  availableLanguages: {
    en_EN: "English",
    fr_FR: "Français",
    it_IT: "Italiano",
    es_ES: "Espagnol",
    ru_RU: "Russian",
    el_GR: "Greek",
    pl_PL: "Polish",
    pt_PT: "Portuguese",
    nl_NL: "Dutch",
    de_DE: "German",
    ro_RO: "Romanian",
  },
  defaultLanguage: "en_EN",
  languageVmMixin: {
    computed: {
      currentKebabCase: function () {
        return this.current.toLowerCase().replace("_", "-");
      },
      currentLang: function () {
        return this.current.replace("_", "-");
      },
    },
    watch: {
      currentLang: function (n, o) {
        console.log("GetTextPlugin:: currentLang o > n ", o, n);
        // Then change html lang attr
        if (n) document.documentElement.setAttribute("lang", n);
      },
    },
  },
  translations: translations,
  silent: true,
});

// Globally load mixin
Vue.mixin(titleMixin);

// import { helpers } from "@/core/statics/helpers.js"; // ADD WIL

// // Globally load on prototype helpers
// // How to use on js : this.$func.functionName(somedata)
// // How to user on templates : $func.functionName(somedata)
// Vue.prototype.$helpers = helpers;

// Globally load on prototype mainFuncs
// How to use on js : this.$func.functionName(somedata)
// How to user on templates : $func.functionName(somedata)
Vue.prototype.$mainFunctions = mainFunctions;

// Globally load filters
// How to use on templates : {{ data | filter params }}
filters.forEach((f) => {
  Vue.filter(f.name, f.execute);
});

// Globally load VueInputMask
// How to user on templates : <input type="text" v-mask="'99/99/9999'" />
// How to user on templates : <input type="text" v-mask="{mask: '99/99/9999', greedy: true}" v-on:change="maskCheck"/>
Vue.use(VueInputMask); // Typoscript way
// test import inputmask
// import * as InputMask from "@/assets/plugins/inputmask/dist/inputmask.min.js"; //https://stackoverflow.com/questions/48426972/importing-javascript-file-for-use-within-vue-component
// import * as VueInputMask from "../node_modules/vue-inputmask/dist/vue-inputmask-browser.js";

// Globally load VueInputMask

// Load VueGoogleMaps
// https://www.digitalocean.com/community/tutorials/vuejs-vue-google-maps
Vue.use(VueGoogleMaps, {
  load: {
    //key: "AIzaSyBzlLYISGjL_ovJwAehh6ydhB56fCCpPQw",
    key: "AIzaSyAyUfW-vazoymre4elGEp7_6DM8i_WHfxc",
    libraries: "places", // This is required if you use the Autocomplete plugin
    // localization
    region: "EN",
    language: "en",
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  installComponents: true,
});

Vue.use(VueExcelXlsx);

// Vue obj
// https://medium.com/dailyjs/tracing-or-debugging-vue-js-reactivity-the-computed-tree-9da0ba1df5f9
// https://forum.vuejs.org/t/how-to-access-vue-from-chrome-console/3606/5
window.App = new Vue({
  router,
  store,
  /*i18n,*/
  vuetify,
  VueGoogleMaps,
  render: (h) => h(App),
}).$mount("#app");

// Main router actions
router.beforeEach(async (to, from, next) => {
  console.info("Router is called");
  console.log("[MAIN] Called router::to,from", to, from, store.getters.getHasOperationDraft, store.getters.getHasPartnerDraft);

  // // Ensure we checked auth before each page load.
  // console.log("[MAIN] [AUTH] [VERIFY_AUTH] check", store.dispatch(VERIFY_AUTH));
  // if (to.name !== "login")
  //   Promise.all([store.dispatch(VERIFY_AUTH)])
  //     .then(() => {
  //       console.log("[MAIN] [AUTH] [VERIFY_AUTH] Got auth, continue");
  //       next();
  //     })
  //     .catch((e) => {
  //       console.log("[MAIN] [AUTH] [VERIFY_AUTH] No auth, stop >> LOGIN");
  //       next("/login");
  //     });
  // else console.log("[MAIN] Router login route is requested");

  // // Test
  // if (to.meta.requiresAuth && !(storage.isAuthenticated() && storage.isConfigLoaded())) {
  //   console.error("[MAIN] [AUTH] this route requires auth, check if logged in", "redirect login");
  //   // this route requires auth, check if logged in
  //   // if not, redirect to login page.
  //   return {
  //     path: "/login",
  //     // save the location we were at to come back later
  //     query: { redirect: to.fullPath },
  //   };
  // }

  // Check if the user is authenticated when route to requiresAuth vue
  // if (to.meta.requiresAuth && to.name !== "login") {
  //   try {
  //     let isVerified = await store.dispatch(VERIFY_AUTH);
  //     console.log("Router beforeEach isVerified::", isVerified);
  //   } catch (e) {
  //     console.error("Router beforeEach ROUTE TO LOGIN ", e);
  //     // If error is NoConfig > then retry

  //     // If error is NoAuth > Re-route to login / keep full path
  //   }
  // }

  // Ensure we get a response from getrootapi before each page load.
  // Promise.all([store.dispatch(INIT)]).then(next);
  Promise.all([store.dispatch(INIT)])
    .then(() => {
      console.log("[MAIN] Router got getrootapi, then can rooting");

      // Ensure we didn't have something to store when from operation or partner
      if (
        from.name === "route.operations.create" ||
        from.name === "route.operations.edit" ||
        from.name === "route.partners.create" ||
        from.name === "route.partners.edit"
      ) {
        // Abort if route is going to this particular condition
        if (
          (from.name === "route.operations.edit" && to.name === "route.operations.edit") ||
          (from.name === "route.operations.edit" && to.name === "route.operations.create") ||
          (from.name === "route.operations.create" && to.name === "route.operations.create")
        )
          next();
        else {
          // Check id drafts if route is going to rights conditions
          if (store.getters.getHasOperationDraft === true || store.getters.getHasPartnerDraft === true) {
            // If there is ≠
            console.log("[MAIN] [EDIT] watch router", "There is ≠ in " + from.name);

            window.swalAlert
              .fire({
                title: router.app.$gettext("Are you sure to leave ?"),
                text: router.app.$gettext("Changes will be lost..."),
                icon: "question",
                showCancelButton: true,
                confirmButtonText: router.app.$gettext("Yes, discard changes"),
                cancelButtonText: router.app.$gettext("No, continue editing"),
                reverseButtons: true,
                timer: undefined,
              })
              .then((result) => {
                if (result.isConfirmed) {
                  //swalMixin.fire(router.app.$gettext("Redirected"), router.app.$gettext("You can continue routing."), "success");
                  store.dispatch(RESET_ALL_DRAFT).then(() => {
                    next();
                  });
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                  //swalMixin.fire(router.app.$gettext("Cancelled"), router.app.$gettext("You can keep editing your form."), "success");
                  return false;
                }
              });
          } else {
            // If there is no ≠
            console.log("[MAIN] [EDIT] watch router", "There is no ≠ in " + from.name);
            next();
          }
        }
      } else {
        next();
      }
    })
    .catch((e) => {
      console.error("[MAIN] [INIT] CATCH ERROR, Cannot get root api", e);
      window.swalToast.fire({
        title: router.app.$gettext("Error : Cannot get root api"),
        text: e,
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        //heightAuto: false,
      });
    });

  // Flush operations filters and searchs
  if (
    (from.name === "route.operations.list" && to.name === "route.operations.edit") ||
    (from.name === "route.operations.edit" && to.name === "route.operations.list") ||
    (from.name === "route.operations.edit" && to.name === "route.operations.edit")
  ) {
    console.log("[MAIN] Router DO NOT FLUSH filters and searchs");
  } else {
    console.log("[MAIN] Router FLUSH filters and searchs");
    store.dispatch(RESET_FILTERED_OPERATIONS).then(() => {
      console.log("[MAIN] Router filters and searchs FLUSHED !");
    });
  }

  // Reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

// router.beforeResolve(async (to) => {
//   console.info("Router beforeResolve is called", to, to.meta.requiresAuth, to.name);

//   if (to.meta.requiresAuth && to.name !== "login") {
//     // try {
//     //   await store.dispatch(VERIFY_AUTH)();
//     // } catch (error) {
//     //   if (error instanceof NotAllowedError) {
//     //     console.info("Router beforeResolve error NotAllowedError", error);

//     //     // ... handle the error and then cancel the navigation
//     //     return false;
//     //   } else {
//     //     console.info("Router beforeResolve error", error);

//     //     // unexpected error, cancel the navigation and pass the error to the global handler
//     //     throw error;
//     //   }
//     // }
//     Promise.all([store.dispatch(VERIFY_AUTH)])
//       .then(() => {
//         console.log("[MAIN] [AUTH] [VERIFY_AUTH] Got auth, continue");
//         next();
//       })
//       .catch((e) => {
//         console.log("[MAIN] [AUTH] [VERIFY_AUTH] No auth, stop >> LOGIN");
//         return {
//           path: "/login",
//           // save the location we were at to come back later
//           query: { redirect: to.fullPath },
//         };
//       });
//   }
// });

router.beforeResolve(async (to, from, next) => {
  console.info("Router beforeResolve is called", to, to.meta.requiresAuth, to.name, from, from.name, router.currentRoute.name);

  // Check if the user is authenticated and requiresAuth when route to vue
  if (to.meta.requiresAuth && to.name !== "login") {
    var disconnectInformations = storage.getDisconnectInformations();
    // First get : disconnectInformations === null
    // console.info("Router beforeResolve VERIFY_AUTH", "disconnectInformations::", disconnectInformations);

    try {
      let isVerified = await store.dispatch(VERIFY_AUTH);
      console.log("Router beforeResolve isVerified::", isVerified);
      next();
    } catch (e) {
      console.info("Router beforeResolve ROUTE TO LOGIN ", e);
      // If error is NoConfig > then retry
      if (e === "NoConfig") {
        setTimeout(() => {
          console.info("RETRY >>>>> Router beforeResolve ROUTE TO LOGIN ", from.fullPath, to.fullPath);

          store
            .dispatch(VERIFY_AUTH)
            .then(() => {
              // Ici > Voir pour simplifier > checker plutot la config que la totalité.
              next();
              //router.push({ path: from.fullPath });
            })
            .catch((e) => {
              console.info(
                "FINALLY ABORD >>>>> Router beforeResolve ROUTE TO LOGIN OR DISCONNECTED",
                e,
                disconnectInformations.route_name,
                typeof disconnectInformations.url,
                to.fullPath
              );
              if (disconnectInformations !== null && typeof disconnectInformations !== "undefined") {
                if (typeof disconnectInformations.url === "string " && disconnectInformations.url) window.location = disconnectInformations.url;
                else if (typeof disconnectInformations.route_name === "string " && disconnectInformations.route_name)
                  router.push({ name: disconnectInformations.route_name, query: { redirect: to.fullPath } });
                else router.push({ path: "/login", query: { redirect: to.fullPath } });
              } else {
                //router.push({ path: "login", query: { redirect: to.fullPath } });
                return {
                  path: "/login",
                  // save the location we were at to come back later
                  query: { redirect: to.fullPath },
                };
              }
            });
        }, 1000);
      }

      // If error is NoAuth > Re-route to login / keep full path
      if (e === "NoAuth" && from.name !== "login") {
        console.info(
          "NoAuth ABORD >>>>> Router beforeResolve ROUTE TO LOGIN OR DISCONNECTED",
          e,
          disconnectInformations.route_name,
          typeof disconnectInformations.url,
          to.fullPath
        );
        if (disconnectInformations !== null) {
          if (typeof disconnectInformations.url === "string " && disconnectInformations.url) window.location = disconnectInformations.url;
          else if (typeof disconnectInformations.route_name === "string " && disconnectInformations.route_name)
            router.push({ name: disconnectInformations.route_name, query: { redirect: to.fullPath } });
          else router.push({ path: "/login", query: { redirect: to.fullPath } });
        } else {
          router.push({ path: "/login", query: { redirect: to.fullPath } });
          // return {
          //   path: "/login",
          //   // save the location we were at to come back later
          //   query: { redirect: to.fullPath },
          // };
        }
      }
    }
  }
  // When next() is called, the router will load the component corresponding
  // to the URL path.
  else next();
});
